
.MainContainer {
    display: flex;
    flex-wrap: wrap;
}

.mdc-menu-surface--anchor {
    display: initial;
}


table .rmwc-badge {
    text-transform: capitalize;
    padding: 0 10px;
    min-width: 100px;
}


.PageTitle h1 span {
    font-weight: bold;
    font-size: 1.2em;
}

.mdc-drawer .mdc-list-item__graphic {
    color: rgb(0 0 0);
}

.CardsHome, .CardsMesas {
    margin: 2%;
}

.CustomInputSearch {
    min-width: 300px;
    display: inline-grid;
    background: white !important;
    margin-right: 10px;
    max-height: 51px;
    min-height: 51px;
    margin-top: 1%;
}

div.CustomContainer.mdc-layout-grid div.mdc-layout-grid__inner {
    display: flex !important;
    flex-direction: column !important;
}

:root {
    --mdc-theme-primary: #000000;
}

.mdc-data-table {
    overflow-x: inherit;
}

.BtnDefaultTmenu {
    background: #000!important;
    color: #fff!important;
    padding: 0px 10px!important;
    margin-right: 8px;
    border-radius: 8px;
    max-height: 51px;
    min-height: 51px !important;
    margin-top: 1% !important;
}

.TmenuSuccess {
    background: #4CAF50;
    color: white;
}

.TmenuSuccessText {
    color: #4CAF50;
}

.TmenuDanger {
    background: #b00020;
    color: white;
}

.TmenuDangerText {
    color: #b00020;
}

.TmenuInfo {
    background: #ff9800;
    color: white;
}

.TmenuInfoText {
    color: #ff9800;
}

.TmenuInProcess {
    background: #2196f3;
    color: white;
}

.TmenuInProcessText {
    color: #2196f3;
}

.TmenuPrimary {
    background: black;
    color: white;
}

.TmenuDisabled {
    background: #bbb;
    color: #666;
}

table.mdc-data-table__table a {
    color: black;
    font-weight: bold;
}

.BtnSecondaryAction {
    color:  black !important;
}

.mdc-dialog__content * {
    margin-bottom: 3%;
}

.mdc-dialog__surface {
    padding: 5% 2%;
}

.TabelaProdutos th.rmwc-data-table__cell.mdc-data-table__header-cell {
    font-weight: bold;
}

.mdc-data-table__cell {
    box-sizing: content-box;
}

.mdc-data-table__row {
    border-top-width: 6px;
    border-top-style: solid;
    border-color: #e3e3e3;
}

.AttendedBy {
    font-style: italic;
    color: #737373;
    margin-top: 2%;
    font-weight: 400;
}

li.mdc-ripple-upgraded.mdc-list-item strong {
    padding-left: 2%;
}

.AttendedByNoOne {
    font-style: italic;
    color: red;
    margin-top: 2%;
    font-weight: 400;
}

.mdc-card a {
    text-decoration: none !important;
}

.mdc-menu.mdc-menu-surface.mdc-menu-surface--open i {
    margin-right: 8px;
}

.BtnDefaultSearch {
    height: 51px;
    background: black !important;
    color: white !important;
    font-weight: 600 !important;
    border-radius: 8px;
}

button.rmwc-icon {
    text-align: center;
    margin: 0 auto;
    display: flex;
    margin-top: 3px;
}


.ToFlex {
    display: flex;
}

.ToEnd {
    justify-self: end;
}

.CustomContainer {
    margin: 2%;
}

.CardsHome .mdc-card {
    box-shadow: none;
    text-align: center;
    padding: 5%;
    margin: 0 auto;
}

.CardsMesas .mdc-card {
    box-shadow: none;
    text-align: center;
    padding: 5% 0 0 0;
    margin: 0 auto;
}

.CardsHome i {
    margin: 0 auto;
}

.rmwc-data-table__cell--align-end {
    text-align: center;
}

.CardsHomeTitle, .CardsMesasTitle {
    font-size: 16px;
    color: #737373;
    font-weight: bold;
    padding-top: 2%;
}

.CardsHomeValue {
    font-size: 64px;
    padding-top: 3%;
    font-weight: bold;
}

.CardsMesasValue {
    font-size: 64px;
    font-weight: bold;
}

.PageTitle {
    margin: 0 auto;
    text-align: left;
    height: 72.50px;
    width: 100%;
    margin-left: 2%;
}

.PageTitle h1 {
   text-transform: uppercase;
   font-weight: bold;
   font-size: 1.7rem;
}

.strong {
    font-weight: bold !important;
}

.PageContainer {
    width: calc(100% - 290px);
}

h1, h2, h3, h4, h5, h6, p, span, a {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
}

.logo {
    margin: 3% auto 0% auto;
    display: block;
    padding: 1% 0;
}

.white {
    color: white;
}

.paddingBtn {
    padding: 2% 4% !important;
}

.Menu ul > a {
    font-weight: 600 !important;
}

.Menu {
    height: 100vh !important;
}

.Divider {
    border-color: #ffffff40;
}

.mdc-layout-grid {
    padding: 0;
}

.center{
    text-align: center;
  }
  .pagination{
    display: inline-block;
  }
  .pagination p {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    cursor: pointer;
  }
  .pagination p.active{
    background-color: black;
    color: white;
    border-radius:5px;
  }

  .pagination p.desabled{
    display: none;
  }
  
  .loading{
    margin-left: 35rem;
    margin-top: 13rem;
  }
  
  .formContainer {
    margin: 2rem;
    padding: 1% 2% 2% 2%;
    border-radius: 5px;
    width: 500px;
    background: white;
    max-width: 100%;
  }

  .settingsContainer{
    margin: 2rem;
    padding: 1% 2% 2% 2%;
    border-radius: 5px;
    width: 40rem;
    background: white;
    min-width: 40%;
}


  .panelContainer {
    margin: 2%;
    padding: 1% 2% 2% 2%;
    border-radius: 5px;
    min-width: 700px;
    background: white;
  }
  .LoginContainer {
    width: 40rem;
    margin-top: 10rem;
    margin-left: auto;
    margin-right: auto;
}

.inputCusston{
    border: none;
    font-size: 0.9rem;
}
.infoContainer {
    margin: 2rem;
    padding: 1% 2% 2% 2%;
    border-radius: 5px;
    min-width: 50rem;
    background: white;
    max-width: 100%;
    min-height: 40rem;
  }

  .requiredStyle{
      float: right;
      color: #362727;
      font-size: 0.8rem;
  }

  .descriptionStyle{
      font-size: 1rem;
  }

  .attValue{
    display: flex;
  }
  
  .nameValue{
  font-size: 1rem;
  }
  .addValue{
  margin-left: auto;
  padding: 0rem 0.6rem;
  color: #f00b0b;
  cursor: pointer;
  }

  .btComplement, .bntRemove{
    background: rgb(252, 249, 249)!important;
    color: rgb(3, 3, 3)!important;
    padding: 0px 10px!important;
    margin-right: 8px;
    border-radius: 8px;
    max-height: 51px;
    min-height: 51px !important;
    margin-top: 1% !important;
  }


 .btnEdit{
    background: #ff9800 !important;
    color:#FFFFFF !important
 }
 .btnDelete{
     background: #b00020 !important;
     color: #FFFFFF !important;
 }

 .btnSave{
     background: #2196f3 !important;
     color: #FFFFFF !important;
 }