.btnGroup{
    margin-top: 2rem;
    margin-left: 40rem;
    margin-right: 20rem;
    width: 30rem;
    padding: 2rem;
}

.containerPrinter{
    margin-top: 1rem;
    margin-bottom: 5rem;
    margin-left: 30rem;
    margin-right: 20rem;
    width: 20rem;
    min-height: 20rem;
    background: #FFFFFF !important;
    padding: 2rem;
    display: none;
}
  body > .containerPrinter {
    display: block;
  }
  


.containerPrinter p{
    font-size: 0.8rem;
    font-family: 'Courier New', Courier, monospace;
    line-height: 0.2rem;
}

.title{
    text-align: center;
    font-size: 1.5rem;
}

@media print {
    body {
      margin: 0;
      color: #000;
      background-color: #fff;
    }
     .btnGroup{
        display: none;
    }
    .containerPrinter{
        width: 25rem;
        margin-left: 5rem;
        background: #FFFFFF !important;
        display: block;
    }
    .containerPrinter p{
        font-size: 1rem;
        font-family: 'Courier';
    }
   
    
    .title{
        text-align: center;
        font-size: 1rem;
    }
  }

  @page{
      size: 80mm portrait;
  }

  @page :left {
    margin-left: 3cm;
  }
  
  @page :right {
    margin-left: 4cm;
  }
