li{
    list-style: none;
}

.tablesData{
    margin: 1rem;
    padding: 1rem;
    width: 25rem;
    border: solid 1px;
    border-radius: 5%;
    border-color: #928686;
    overflow-y: scroll;
    height: 30rem;
    scrollbar-color: #928686 #ffffff;
}
.tablesData h4{
    text-align: center;
}

.tablesCard{
    display: inline;
    
}
.tableData{
    display: grid;
    border: none;
    background: #d7f1db;
    border-radius: 5%;
    margin: 0.5rem;
    padding: 0.5rem;
    width: 10rem;
}
.tableTitle{
    text-align: center;
}
.inputDates{
    padding: 1rem;
}

.btnReportUpdate{

}

.cardsDataContainer{
    padding: 10px;
    border-radius: 5%;
    box-shadow: 2px 2px #e2dede;
}
.cardsDataContainer h4{
    margin-left: 7rem;
}
.cardsData{
    display: flex;
    border: none;
    background: #FFFFFF;
    border-radius: 5%;
    padding-top: 2rem;
    padding-bottom: 3rem;
    width: 20rem;
}
.cardsDataChart{
    display: flex;
    border: none;
    background: #FFFFFF;
    border-radius: 5%;
    padding: 0.5rem;
    width: 20rem;
}
.totalCards{
    font-size: 5rem;
    margin-left: 6rem;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.detailsTotalCards{
        margin-left: 3rem;
        width: 10rem;
        line-height: 1.5rem;
}

.detailsTotalCards strong{
    font-size: 0.8rem;
}

.cardsTicket{
    display: flex;
    border: none;
    background: #FFFFFF;
    border-radius: 5%;
    padding: 0.5rem;
    width: 22rem;
    text-align: center;
}
.dataTicket{
    text-align: center;
    background: #d7f1db;
    border-radius: 5%;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.dataTicket strong{
    font-size: 1rem;

}

.maxTicket{
    margin-top: 1rem;
    font-size: 1.5rem;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}



.cardsTicketContainer{
    display: inline;
    padding: 7px;
    border-radius: 5%;
    box-shadow: 2px 2px #e2dede;
}

.cardsTicketContainer h4{
    margin-left: 7rem;
}

.dataWaiterContainer{
    display: inline;
    padding: 7px;
    border-radius: 5%;
    box-shadow: 2px 2px #e2dede;
}

.dataWaiterContainer h4{
    margin-left: 7rem;
}

.rate{
    font-size: 3rem;
    padding: 1rem;
    margin-left: 3rem;
}
.rateCard{
    display: grid;
    margin: 1rem;
}

.rateDetail{
    display: flex;
}

.rateDetail strong{
 font-size: 0.8rem;
}

.rateInfo{
    display: grid;
    padding: 1rem;
    margin-left: 3rem;
}