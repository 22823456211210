.title{
    margin-left: 2rem;
}

.abas{
    margin-left: 1rem;
}
.abas span{
    margin-left: 1rem;
    cursor: pointer;
}
.abas i{
    margin-left: 1rem;
}
.complementContainer{
    margin-left: 2rem;
    margin-top: 1rem;
}
.imageContainer{
    margin-left: 2rem;
    margin-top: 1rem;
}
.prodcutImageDetail{

}


.optionDelete{
display: none;
}

.prodcutImageDetail:hover .optionDelete{
    display: block;
    cursor: pointer;
}