.containerPrint{
    padding: 2rem;
    display: grid;
}
div.containerPrintNewOrder{
    width: 20rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    border: solid 1px;
    padding: 1rem;
}