.container{
    margin: 2rem;

}

.title{
    margin-left: 2rem;
}

.gridContainer{
    margin: 1rem;
}
.select{
    font-size: 1rem;
    color: rgb(3, 3, 3);
    padding: 0.3rem;
}

.btnPrintOrder{
    background: #cfcccc !important;
    width: 10rem;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    border-radius: 8px;
}

.btnRemove{
    background: #eb0909 !important;
    color: #FFFFFF !important;
    width: 8rem;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    border-radius: 8px;
}

.BtnDefaultTmenu {
    background: #000!important;
    color: #fff!important;
    padding: 0px 10px!important;
    margin-right: 8px;
    border-radius: 8px;
    max-height: 51px;
    min-height: 51px !important;
    margin-top: 1% !important;
}

.dataListProduct{
display: grid;
}
.printOrderCard{
    display: none;
}
.drawerOrder{
    padding: 1rem !important;
}

@media print {
    .printOrderCard{
        display: block;
    }
}