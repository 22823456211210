.modal {
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }

  .modalContent {
    background-color: #fefefe;
    margin-left: auto;
    border: 1px solid #888;
    width: 50rem;
    min-height: 100%;
    animation-name: animaterigth;
    animation-duration: 0.4s
  }
  .dialogContent {
    background-color: #fefefe;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #888;
    width: 50rem;
    min-height: 100%;
  }
  .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    margin: 1rem;
  }
  
  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }


  .closeButton{
    position: fixed;
    bottom: 0;
    margin: 1rem;
    font-size: 1rem;
    cursor: pointer;
  }

  .loadingDialog{
    margin-left: 22rem;
    margin-top: 18rem;
  }


  @keyframes animaterigth {
    from {right: -300px; opacity: 0}
    to {right: 0; opacity: 1}
  }