.containerPrinterOrder{
    background: #FFFFFF !important;
    min-width: 10rem;
    min-height: 12rem;
    padding: 1rem;
    line-height: 1.5rem;
}

.tableInfo{
    display: flex;
    align-items: center;
    font-size: 1em;
}
.descriptionOrder{
    font-size: 1rem;
}
.attributeName{
    font-size: 1rem;
}

.value{
    font-size: 1rem;
    margin-left: 1rem;
}
.orderNumber{
    font-size: 1rem;
}

.totalOrder{
    margin-left: auto;
}

@media print {
* {
    background:transparent !important;
    color:#000000 !important;
    text-shadow:none !important;
    filter:none !important;
    -ms-filter:none !important;
    }
body {
    margin:0;
    padding:0;   
    }
  
    .containerPrinterOrder{
        line-height: 0.6rem !important;
        font: 12pt Georgia, "Times New Roman", Times, serif !important;
    }
    .descriptionOrder{
        font-size: 16pt;
    }
    .attributeName{
        font-size: 14pt;
    }

    .value{
        font-size: 12pt;
    }
  }
  @page {
    margin: 0.5cm;
    }  

  @page{
      size: 80mm portrait;
  }

  @page :left {
    margin-left: 1cm;
  }
  
  @page :right {
    margin-left: 1cm;
  }
