.CardsMesas .mdc-layout-grid__inner {
    display: flex;
    min-width: 310px;
    justify-content: space-evenly;
}

.CardsMesasAlertZone * {
    padding: 0 0.8%;
    cursor: pointer;
}

.CardsMesasAlertZone i {
    line-height: 30px !important;
}

.AlertZoneRequestTheBill {
    background: #FF4444 !important;
    color: white !important;
}

.AlertZoneRequestNewOrder {
    background: #292929 !important;
    color: #ffeb3b !important;
}
.AlertZoneRequestNewOrder .rmwc-badge {
    background: #505050 !important;
    color: #fdea3c !important;
}


.CardsMesas .mdc-menu.mdc-menu-surface {
    width: 175px;
}

.CardsMesasAlertZone {
    background: #ccc;
    margin-top: 5%;
    padding: 3% 1%;
    display: flex;
    justify-content: center;
}

a .CardsMesasAlertZone{
    text-decoration: none !important;
    height: 20px;
    align-items: center;
    color: #333;
}

.CardsMesas.mdc-layout-grid {
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
}

.CardsMesas .rmwc-badge {
    font-weight: 300;
    text-transform: initial;
    letter-spacing: 1px;
    font-size: 0.8em;
    padding: 0px 10px;
}


.accountContainer{
    margin: 1rem;
}